import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import DigitPasswordInput from '../../../../components/DigitPasswordInput';

import styles from '../../Auth.module.scss';

export default function AuthPasswordStep({
  onSubmit, passwordLength, changePhoneLink, resendPasswordLink, isLoading, onClickHelp,
}) {
  const [isStepValid, setIsStepValid] = useState(false);
  const password = useRef();

  const checkStepValid = () => {
    setIsStepValid(password.current.length === passwordLength);
  };

  const handleSubmitClick = () => {
    onSubmit(password.current);
  };

  const handlePasswordChange = (p) => {
    password.current = p;
    checkStepValid();
  };

  return (
    <>
      <div className={styles.Auth__head}>
        <h1 className={styles.Auth__stepHeader}>Введите пароль из СМС</h1>
        <a
          type='button'
          className={styles.Auth__helpButton}
          onClick={() => onClickHelp()}
        >
          Помощь
        </a>
      </div>

      <div className={styles.Auth__inputWrap}>
        <DigitPasswordInput
          onChange={handlePasswordChange}
          digits={passwordLength}
          className="digitPasswordInput"
        />
      </div>

      <div className={styles.Auth__inputInfo}>
        {resendPasswordLink}
        {changePhoneLink}
      </div>

      <div className={styles.Auth__buttonWrap}>
        <button
          className={cn('button button_large button_fullwidth', styles.Auth__bg)}
          disabled={!isStepValid || isLoading}
          onClick={handleSubmitClick}
          type="submit"
        >
          Войти
        </button>
      </div>
    </>
  );
}

AuthPasswordStep.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  passwordLength: PropTypes.number,
  changePhoneLink: PropTypes.element,
  resendPasswordLink: PropTypes.element,
  isLoading: PropTypes.bool,
  onClickHelp: PropTypes.func.isRequired,
};

AuthPasswordStep.defaultProps = {
  passwordLength: 4,
  changePhoneLink: null,
  resendPasswordLink: null,
  isLoading: false,
};
