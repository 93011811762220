import React from 'react';
import PropTypes from 'prop-types';

import CallWriteSnippet from '../../../../components/CallWriteSnippet';

export default function AuthContacts({ onClose, contact, full }) {
  return (
    <div className="contactPage">
      <div className="contactPage__header" />
      <div className="contactPage__content">
        <CallWriteSnippet contact={contact} />

        {full && (
          <>
            {contact.image_url && (
              <div className="contactPage__fullWidthImage">
                <img alt="" src={contact.image_url} />
              </div>
            )}

            <div className="contactPage__descriptionWrap">
              <p><strong>{contact.description_header}</strong></p>
              <p>
                <div
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{ __html: contact.description_text }}
                />
              </p>
            </div>

            <div className="contactPage__addressWrap">
              <div className="contactPage__addressHeader">
                <strong>{contact.address_header}</strong>

                {contact.address_latitude && contact.address_longitude && (
                  <a
                    className="contactPage__addressLink"
                    href={`https://www.google.com/maps/search/?api=1&query=${contact.address_latitude},${contact.address_longitude}`}
                  >
                    На карте
                  </a>
                )}
              </div>
              <div>{contact.address_text}</div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

AuthContacts.propTypes = {
  onClose: PropTypes.func,
  contact: PropTypes.arrayOf(PropTypes.shape({
    phone: PropTypes.string,
  })).isRequired,
  full: PropTypes.bool,
};

AuthContacts.defaultProps = {
  full: false,
};
